import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExtendedAnimal } from './severity-score-modal.component';
import { FormGroup } from '@angular/forms';
import { arrowRight } from '@common/icons';
import { TranslationService } from '@services/translation.service';
import { JobService } from '../../jobs/job.service';
import { Animal } from '@common/types';

@Component({
    selector: 'severity-score-warning-modal',
    templateUrl: './severity-score-warning-modal.component.html',
    styleUrls: ['./severity-score-modal.component.scss', './severity-score-warning-modal.component.scss'],
})
export class SeverityScoreWarningModalComponent implements OnInit {
    @Input() animals: ExtendedAnimal[];
    @Input() form: FormGroup;
    @Input() newSeverityScore: string;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    public displayedColumns: string[] = ['select', 'name', 'recordedSeverityScores', 'newSeverityScore'];
    public animalsDataSource: ExtendedAnimal[] = [];
    public totalCount: number;
    public isPageAllSelected = true;
    public pageIndex = 0;
    public pageSizeOptions = [5, 10, 15];
    public pageSize = this.pageSizeOptions[0];
    public icons = { arrowRight };
    public message: string;

    constructor(private translationService: TranslationService, public jobService: JobService) { }

    public ngOnInit(): void {
        if (this.animals?.length) {
            this.totalCount = this.animals.length;
            this.animalsDataSource = this.animals.slice(0, this.pageSize);
            const jobNames = [...new Set(
                this.animals.reduce((jobNames, animal) => {
                    const jobs = animal.SeverityScore.map(item => item.Job?.JobID && !this.jobService.isJobInEndState(item.Job));
                    return jobNames.concat(jobs);
                }, []) 
            )].filter(jobName => jobName);
            const animalLabel = this.animals.length === 1 ? 'animal' : 'animals';
            const jobLabel = jobNames.length === 1 ? this.translationService.translate('job') : this.translationService.translate('jobs');
            this.message = `You are about to update severity scores for ${this.animals.length} ${animalLabel} on ${jobLabel} ${jobNames.join(', ')}. Please confirm your choice.`;
        }
    }

    public getRecordedSeverityScores(animal: Animal): string {
        return animal.SeverityScore
            .filter(severityScore => severityScore.C_Job_key && !this.jobService.isJobInEndState(severityScore.Job))
            .map(severityScore => severityScore?.cv_Severity?.Severity)
            .join(', ');
    }

    public toggleRowSelect(row: ExtendedAnimal): void {
        row.isSelected = !row.isSelected;
        const newValue = this.animals.filter(item => item.isSelected);
        this.isPageAllSelected = this.checkPageAllSelected(this.animalsDataSource);
        this.form.get('Animals').setValue(newValue);
    }

    public togglePageSelected(): void {
        for (const animal of this.animalsDataSource) {
            animal.isSelected = !this.isPageAllSelected;
        }
        this.isPageAllSelected = this.checkPageAllSelected(this.animalsDataSource);
        const newValue = this.animals.filter(item => item.isSelected);
        this.form.get('Animals').setValue(newValue);
    }

    private checkPageAllSelected(animals: ExtendedAnimal[]): boolean {
        return animals.every(animal => animal.isSelected);
    }

    public changeAnimalsTablePage(pageIndex: number): void {
        this.pageIndex = pageIndex;
        const skip = pageIndex * this.pageSize;
        this.animalsDataSource = this.animals.slice(skip, this.pageSize + skip);
        this.pageChange.emit(pageIndex);
    }

    public pageSizeChanged(event: number): void {
        this.pageSize = event;
        this.animalsDataSource = this.animals.slice(0, this.pageSize);
    }
}
